.table {
    .cellWrap {
        display: flex;
        align-items: center;

        img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }
    .status {
        padding: 5px;
        border-radius: 5px;

        &.Approved {
            color: green;
            background-color: rgba(0, 128, 0, 0.151);
        }

        &.Pending {
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.103);
        }
    }
}
