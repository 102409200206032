.navbar {
    height: 70px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;
    font-size: 30px;
    color: grey;
    .btn{
        font-size: 1.5rem;
    }
    .wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
        .search {
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;
            margin-left: 1rem;
            border-radius: 10px;

            input {
                border: none;
                outline: none;
                background-color: transparent;

                &::placeholder {
                    font-size: 12px;
                }
            }
        }
        .items {
            display: flex;

            .item {
                display: flex;
                align-items: center;
                margin: 0 20px;
                position: relative;
                .icon {
                    font-size: 20px;
                }
                .iconLogin{
                    font-size: 30px;
                    margin-right: 15px;
                }

                .avatar {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                }

                .counter {
                    height: 15px;
                    width: 15px;
                    background-color: red;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                }
            }
        }
    }
}
