.widget {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 10px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.47) 2px 4px 10px 1px;
    box-shadow: rgba(201, 201, 201, 0.47) 2px 4px 10px 1px;
    border-radius: 10px;
    height: 100px;
    background-color: #fff;
    .left,
    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        font-weight: bold;
        font-size: 14px;
        color: rgba(160, 160, 160, 1);
    }

    .counter {
        font-size: 28px;
        font-weight: 300;
    }

    .link {
        width: max-content;
        font-size: 12px;
        border-bottom: 0.5px solid rgba(160, 160, 160, 1);
    }

    .percentage {
        display: flex;
        align-items: center;
        font-size: 14px;

        &.positive {
            color: green;
        }
        &.negative {
            color: red;
        }
    }

    .icon {
        font-size: 30px;
        padding: 5px;
        border-radius: 5px;
        align-self: flex-end;
    }
}
