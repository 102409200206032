@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
* {
    font-family: 'Nunito', sans-serif;
}

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f5f4f4;
    font-size: 10px;
    height: 100%;
}
#root, .app, .tmn{
    height: 100%;
}
.bgColor1Taman{
    background-color: #F34830;
}