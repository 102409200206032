.login{
    display: flex;
    flex-direction: column;
    height: 100%;

    .loginContainer{
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 90vw;
        max-width: 600px;
        text-align: center;
            .image-dash{
                img{
                    width: 90vw;
                    max-width: 300px;
                    margin-bottom: 100px;
                }
            }
            .btn-login{
                margin-top: 2rem;
                font-size: 2.5rem;
                width: 200px;
                height: 80px;
                margin: auto;
            }

    }
}