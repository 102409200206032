.tmn {
    display: flex;
    .loader {
        margin: auto;
    }
    .querrier {
        margin-top: 20px;
        margin-bottom: 1.5rem;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
    .widgets,
    .charts {
        display: flex;
        gap: 20px;
        width: 95%;
    }
    .infoNbMissions{
        width: 90%;
    }
    .section-ca{
        display: flex;
        margin: 15px auto;
        width: 95%;
        display: flex;
        justify-content: space-around;
        .section-full-width{
            // width: 100%;
            width: 33%;
        }
        .section-left{
            flex: 1;
            padding: 0 20px 0 0;
            position: relative;
            
            .widget{
                height: 100%;

                .left{
                    flex: 7;
                    .counter{
                        text-align: center;
                    }
                }
                .right{
                    flex: 1;
                }
            }
        }
        .section-right{
            flex: 2;
            position: relative;
        }
    }
    .tmnContainer {
        flex: 6;
        display: flex;
        flex-direction: column;
        .MuiPaper-root{
            border-radius: 13px;
            padding: 0 !important;
        }
        .charts,
        .features {
            display: flex;
            padding: 20px 20px 0 20px;
            gap: 20px;
        }

        .charts {
            padding: 5px 20px;
        }

        .listContainer {
            -webkit-box-shadow: rgba(0, 0, 0, 0.47) 2px 4px 10px 1px;
            box-shadow: rgba(201, 201, 201, 0.47) 2px 4px 10px 1px;
            padding: 20px;
            margin: 20px;
            border-radius: 10px;
            width: 95%;
            background-color: #fff;
            .listTitle {
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }

        .recharts{
            .datagrid {
                font-size: 18px;
            }
        }
    }
}
