@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

    .loading-spinner {
        width: 10rem;
        height: 10rem;
        border: 10px solid #c7c4c4;
        margin: auto;
        /* Light grey */
        border-top: 10px solid #383636;
        /* Blue */
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
    }
    .loading-text{
        margin: 1rem auto;
        font-size: 3rem;
    }
}
