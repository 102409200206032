.chart {
    flex: 4;
    -webkit-box-shadow: rgba(0, 0, 0, 0.47) 2px 4px 10px 1px;
    box-shadow: rgba(201, 201, 201, 0.47) 2px 4px 10px 1px;
    padding: 10px;
    color: gray;
    border-radius: 10px;
    background-color: #fff;
    .title {
        margin-bottom: 20px;
    }

    .chartGrid {
        stroke: rgb(228, 225, 225);
    }
}
