.list {
    display: flex;
    width: 80%;
    margin: auto;

    .listContainer {
        flex: 6;
        font-weight: 550;
        .userFilter{
            display: none;
        }
        .querrier{
            margin-bottom: 5rem !important;
        }
       
    }
}