.recharts {
    display: flex;
    flex-direction: column;
    align-items: center;
    .features {
        margin: auto;
        display: flex;
        width: 90%;
        padding: 0 20px 20px 20px;
        justify-content: center;
        .featured {
            -webkit-box-shadow: rgba(0, 0, 0, 0.47) 2px 4px 10px 1px;
            box-shadow: rgba(201, 201, 201, 0.47) 2px 4px 10px 1px;
            border-radius: 10px;
            width: 100%;
            .top {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: gray;

                .title {
                    font-size: 18px;
                    font-weight: 500;
                }

                .subtitle {
                    font-size: 12px;
                    font-weight: 300;
                }
            }

            .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 15px;

                .featuredChart {
                    .MuiCircularProgress-circle{
                        width: 10px;
                        height: 10px;
                    }
                }

                .title {
                    font-weight: 500;
                    color: gray;
                }

                .amount {
                    font-size: 30px;
                }

                .desc {
                    font-weight: 300;
                    font-size: 12px;
                    color: gray;
                    text-align: center;
                }

                .summary {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .item {
                        text-align: center;

                        .itemTitle {
                            font-size: 14px;
                            color: gray;
                        }

                        .itemResult {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 10px;
                            font-size: 14px;

                            &.positive {
                                color: green;
                            }
                            &.negative {
                                color: red;
                            }
                        }
                    }
                }
            }
        }
    }
}
