#filters {
width: 100%;
display: flex;
justify-content: center;

    form {
        width: 100%;

        justify-content: space-between;
        .form-check-label{
            font-size: 21px;
        }
        .form-label {
            font-size: 21px;
        }
        .form-control{
            font-size: 21px;
        }
        #hide_archived{
            height: 15px;
            width: 15px;
            // margin-right: 5px;
        }
        .btnSendQuery{
            font-size: 20px;
        }
    }
}