.error {
    height: 100vh;
    width: 100%;
    display: flex;
    background-color: orange;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .errorMsg {
        font-size: 2rem;
        color: #7451f8;
        margin-bottom: 3rem;
    }

    .link_home {
        padding: 20px;
        border-radius: 10px;
        background-color: #7451f8;
        color: white;
    }
}
