.tableContainer{
    width: 95% !important;
    margin: 3rem auto 4px auto ;
    

    .colTitle {
        font-size: 18px;
        font-weight: 550;
        text-align: center;
    }
    .colTotal{
        background: rgba(221, 221, 221, .6);
    }
    .recrutyNames{
        font-size: 17px;
        font-weight: 600;
        text-align: center;
        .name{
            line-height: 18px;
        }
        .firstname{
            padding-bottom: 10px;
        }
    }
    .rowTitle{
        font-size: 18px;
        font-weight: 550;
    }
    .rowPercentage{
        font-size: 18px;
        font-weight: 550;
        text-align: center;
    }
    .rowChiffres{
        font-size: 18px;
        font-weight: 550;
    }
    .chiffres{
        font-size: 20px;
        padding-left: 3rem !important;

    }
}
